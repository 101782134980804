import { getEnvironment } from '../Environment'
import httpService from '../ServiceProvider/ServiceProvider'
import { signOut } from '@aws-amplify/auth'
import { IGetCommunitiesApiResponse } from '../interfaces'
import { ISalesPadResponse } from '../interfaces'
import axios from 'axios'

const environment = getEnvironment()

export const getCommunitiesData = async (
  userId: string,
  token: string
): Promise<IGetCommunitiesApiResponse | undefined> => {
  try {
    const response = await httpService.post(
      environment.communities,
      {
        userId,
        subdomain: environment.subDomain,
      },
      token
    )
    return response.data as IGetCommunitiesApiResponse
  } catch (err: unknown) {
    if (err) {
      signOut().finally(() => {
        window.location.reload()
      })
    }
    console.error(err)
    return undefined
  }
}

export const getEulaContent = async (url: string) => {
  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'text/html',
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

interface IEulaAccepted {
  EULA_accepted: boolean
}

interface IEulaAcceptance {
  url: string
  queryParams: IEulaAccepted
  idToken: string
}
export const acceptEula = async (params: IEulaAcceptance) => {
  try {
    const response = await httpService.put(
      params.url,
      params.queryParams,
      params.idToken
    )
    return response
  } catch (error) {
    console.log(error, 'Error')
  }
}

export const getSalesPadInfo = async (
  orgId: string,
  userId: string,
  token: string
): Promise<ISalesPadResponse> => {
  const queryParams = {
    userId,
    orgId,
    start: '0',
    limit: '-1',
  }
  try {
    const response = await httpService.get(
      environment.getSalesPadsUrl,
      queryParams,
      token
    )
    return response.data
  } catch (error) {
    if (error) signOut()
    throw new Error()
  }
}

export const sendCodeRequest = async (screenName: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = httpService.postWithoutToken(
      environment.forgotPassword,
      {
        userName: screenName,
      }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export interface ResetPasswordPayLoad {
  userName: string | null
  password: string
  otp: string
}

export const resetPassword = async (
  userObj: ResetPasswordPayLoad,
  idToken: string | null | undefined
) => {
  try {
    const response = httpService.post(
      environment.resetPassword,
      {
        userName: userObj.userName,
        password: userObj.password,
        otp: userObj.otp,
      },
      String(idToken)
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const CreateSalesRepUserInfo = (
  orgId: string,
  userId: string,
  token: string
) => {
  return httpService.post(
    environment.createSalesRepUserInfoUrl,
    { orgId, userId },
    token
  )
}

export const getRedirectiontoken = (
  userId: string,
  domain: string,
  token: string
) => {
  return httpService.get(
    environment.generateV3TokenUrl,
    { userId, domain },
    token
  )
}
