import { useNavigate } from '@tanstack/react-location'
import './LandingPage.scss'
import { signOut } from '@aws-amplify/auth'
import { ReactElement } from 'react'

const NoAccess = (): ReactElement => {
  const navigate = useNavigate()
  const handleSignOut = () => {
    signOut()
    localStorage.clear()
    navigate({ to: '/login', replace: true })
  }

  return (
    <div>
      <div>
        <div className="userContainer">
          <div className="comingSoon-block">
            <div className="access-block">
              <p>
                <b style={{ color: 'red' }}>
                  "You aren't associated to any community"
                </b>
              </p>
              <p>
                <b style={{ color: 'red' }}>
                  Please contact myplace administrator for more details.
                </b>
              </p>
            </div>
            <input
              className="btn-signout"
              type="button"
              value="SIGN OUT"
              id="submit-button"
              onClick={() => handleSignOut()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoAccess
