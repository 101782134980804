import { IUserAttributes } from '../interfaces'
import './LandingPage.scss'

interface IAcessProps {
  userAttributes: IUserAttributes
}

const ErrorAccess = (props: IAcessProps) => {
  const { userAttributes } = props

  const handleSignin = () => {
    console.log('Singing in')
  }

  const handleSignout = () => {
    console.log('Singing out')
  }

  return (
    <div>
      <div className="welcome-block">
        <p className="welcome-txt">Welcome to MyPlace4Parts.</p>
      </div>
      <div>
        <div className="userContainer">
          <div className="user-block">
            <p className="userName">
              Welcome <b>{userAttributes.userName}</b>
            </p>
            <img
              className="userLogo"
              src="assets/mpfp-logo.png"
              onClick={handleSignin}
            />
            <input
              className="btn-signout"
              type="button"
              value="SIGN OUT"
              id="submit-button"
              onClick={handleSignout}
            />
          </div>
        </div>
      </div>
      <div className="thanks-block">
        <p className="thanks-txt">
          Thank you for logging in! Click the logo to access MyPlace4Parts.
        </p>
        {/* <p className="thanks-txt2">
          You also have access to your supplier MyPlace Dashboard
        </p> */}
      </div>
    </div>
  )
}

export default ErrorAccess
