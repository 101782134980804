import { useNavigate } from '@tanstack/react-location'
import { usePostPayload } from '../utils/PostPayloadContext'
import LoginComponentUserAttributes from '../utils/UserAttributes'
import SalesPadUserModal from '../SalesPadGrid/SalesPadUserModal'
import { ICommunity, IOrganization, IUserAttributes } from '../interfaces'
import logo from '../assets/mpfp-logo.png'
import { signOut } from '@aws-amplify/auth'
import { ReactElement } from 'react'

interface IAcessProps {
  userAttributes: IUserAttributes
}

const MyPlaceAcess = (props: IAcessProps): ReactElement => {
  const { userAttributes } = props
  const { shouldDisplayV3Logo, handleV3SignIn, handleSignIn } =
    LoginComponentUserAttributes()
  const { openSalesPadModal } = usePostPayload()
  const navigate = useNavigate()

  const handleV3Sign = () => {
    handleV3SignIn(userAttributes)
  }

  const handleSignInUser = (myplaceInfo: ICommunity[]) => {
    handleSignIn(myplaceInfo, userAttributes)
  }

  const handleSignout = () => {
    signOut()
    localStorage.clear()
    navigate({ to: '/login', replace: true })
  }

  return (
    <div>
      {userAttributes.myplaceAccess && (
        <div>
          <div className="welcome-block">
            <p className="welcome-txt">Welcome to MyPlace4Parts</p>
          </div>
          <div>
            <div className="userContainer">
              <div className="user-block">
                <p className="userName">
                  Welcome <b>{userAttributes?.displayUserName}</b>
                </p>
                {shouldDisplayV3Logo(userAttributes) && (
                  <img
                    className="userLogoLogin"
                    src={userAttributes.V3Access.v3Logo}
                    onClick={handleV3Sign}
                  />
                )}
                {userAttributes.V3Access.acessType === '0' && (
                  <img
                    className="userLogoLogin"
                    src={logo}
                    onClick={() => handleSignInUser(userAttributes.myplaceInfo)}
                  />
                )}
                <input
                  type="button"
                  onClick={handleSignout}
                  className="btn-signout"
                  value={'SIGN-OUT'}
                />
              </div>
            </div>
          </div>
          <div className="thanks-block">
            <p className="thanks-txt">
              Thank you for logging in! Click the logo to access MyPlace4Parts.
            </p>
            <p className="thanks-txt2">
              You also have access to your supplier MyPlace Dashboard
            </p>
          </div>
          <div className="bottom-logos-block">
            {userAttributes.orgsInfo.map((org: IOrganization | ICommunity) => {
              return (
                <img
                  src={org.logo}
                  className="orgLogo"
                  onClick={() => handleSignInUser(userAttributes.orgsInfo)}
                />
              )
            })}
            {userAttributes.V3Access?.acessType == '2' && (
              <p className="thanks-txt2" style={{ marginTop: '15px' }}>
                The legacy version of MyPlace will remain available for a
                limited period of time.
              </p>
            )}
            {userAttributes.V3Access?.acessType == '2' && (
              <p
                className="thanks-txt2 shop-navigation"
                onClick={() => handleSignInUser(userAttributes.myplaceInfo)}
              >
                Click here to access it
              </p>
            )}
          </div>
          <div>{openSalesPadModal && <SalesPadUserModal />}</div>
        </div>
      )}
    </div>
  )
}
export default MyPlaceAcess
