import { useCallback } from 'react'
import { usePostPayload } from './PostPayloadContext'

const useAutoLogin = () => {
  const { postPayload } = usePostPayload()
  const autoLogin = useCallback(
    (friendly_URL: string, language: string, formUrl: string) => {
      const form = document.createElement('form')

      const createHiddenInput = (
        name: string,
        value: string | null | undefined
      ) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = String(value)
        return input
      }
      console.log(postPayload)
      form.appendChild(createHiddenInput('orgId', postPayload?.orgId))
      form.appendChild(createHiddenInput('userId', postPayload?.LastAuthUser))
      form.appendChild(
        createHiddenInput('LastAuthUser', postPayload?.LastAuthUser)
      )
      form.appendChild(
        createHiddenInput('refreshToken', postPayload?.refreshToken)
      )
      form.appendChild(createHiddenInput('clockDrift', postPayload?.clockDrift))
      form.appendChild(
        createHiddenInput('deviceGroupKey', postPayload?.deviceGroupKey)
      )
      form.appendChild(createHiddenInput('deviceKey', postPayload?.deviceKey))
      form.appendChild(
        createHiddenInput('accessToken', postPayload?.accessToken)
      )
      form.appendChild(
        createHiddenInput('randomPasswordKey', postPayload?.randomPasswordKey)
      )
      form.appendChild(createHiddenInput('idToken', postPayload?.idToken))
      form.appendChild(createHiddenInput('friendlyURL', friendly_URL))
      form.appendChild(
        createHiddenInput('screenName', localStorage.getItem('userName'))
      )
      form.appendChild(createHiddenInput('userLangId', language))
      form.method = 'POST'
      console.log(formUrl, 'formUrl')

      const formUrlToSubmit = formUrl
      form.action = formUrlToSubmit
      form.hidden = true
      document.body.appendChild(form)
      form.submit()
    },
    [postPayload]
  )

  return autoLogin
}

export default useAutoLogin
