import axios from 'axios'

import { UserAttributes } from '../interfaces'
import { UserAttributesResp } from '../interfaces'
import { getAwsConfig } from '../aws-exports'

export const getKeyTypePair = `query Get($key: String, $type: String) {
    get(key: $key, type: $type) {
      result
    }
  }`

export const getUserAttributes = (
  userId: number,
  token: string
): Promise<UserAttributes> => {
  const graphQL = {
    query: getKeyTypePair,
    variables: { type: 'get', key: `user:${userId}` },
  }
  return axios
    .post<UserAttributesResp>(
      getAwsConfig().aws_appsync_graphqlEndpoint,
      graphQL,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((resp) => {
      return JSON.parse(resp.data.data.get.result) as UserAttributes
    })
}
