import { ReactElement, useState } from 'react'
import './ForgotPassword.scss'
import { getEnvironment } from '../Environment'
import { sendCodeRequest } from '../Services/WidgetService'
import { useNavigate } from '@tanstack/react-location'
import logo from '../assets/mpfp-logo.png'

const environment = getEnvironment()

const ForgotPassword = (): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [screenName, setScreenName] = useState<string>('')
  const navigate = useNavigate()
  const sendCode = () => {
    if (screenName === '' || !screenName.length) {
      setErrorMessage('Please Enter a Screen Name')
    } else {
      sendCodeRequest(screenName)
        .then((response) => {
          if (response?.data?.statusCode == 1006) {
            setErrorMessage(response?.data['message'])
          } else {
            if (response?.data?.statusCode == 200) {
              environment.userName = screenName
              window.localStorage.setItem('userName', screenName)
              const message = response.data['message'][0]
              environment.userMailId = message.Destination
              navigate({
                to: '/reset-password',
              })
            } else {
              setErrorMessage(response?.data['message'])
            }
          }
        })
        .catch((error: unknown) => {
          console.log('error in sending code', error)
        })
    }
  }
  return (
    <div className="forget_screen">
      <div className="loginParentContaniner">
        <div className="loginContainer">
          <div className="loginForm">
            <img className="fs-main-logo" src={logo} alt="Logo" />
            <h6 className="reset-title">ENTER SCREEN NAME</h6>
            <p className="reset-caption">
              Please enter your screen name to send verification to your Email
              ID
            </p>
            {/* <p className="resetpassword-error"></p> */}
            <input
              className="input_username_fp"
              type="text"
              id="screenName"
              name="NewPassword"
              value={screenName}
              placeholder="Enter Screen Name"
              onChange={(e) => setScreenName(e.target.value)}
              onKeyUp={(e) => e.key === 'Enter' && sendCode()}
            />
            <input
              className="btn_signIn"
              type="button"
              value="SEND CODE"
              id="submit-button"
              onClick={sendCode}
              onKeyUp={(e) => e.key === 'Enter' && sendCode()}
            />
            <div className="clearFix"></div>
            <p className="errormessages_cls">{errorMessage}</p>
            <p className="redirect-click">
              Click here to{' '}
              <a className="redirect-login" href="/login">
                <b>LOGIN</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
