import { Environment } from '../src/interfaces'

const environmentProduction: Environment = {
  formSubmitUrl: 'shop.myplace4parts.com/UserLogin/autologin',
  communities:
    'https://allianceconnect.com/o/aapa_jax_rest_services/cognito/user-communities',
  token: '',
  userName: '',
  userMailId: '',
  subDomain: '',
  forgotPassword:
    'https://allianceconnect.com/o/aapa_jax_rest_services/cognito/send-forgotpassword-otp',
  resetPassword:
    'https://allianceconnect.com/o/aapa_jax_rest_services/cognito/validateotp-changepassword',
  eula_restPassword:
    'https://allianceconnect.com/o/aapa_jax_rest_services/organization/user/reset-password',
  v3Url: 'pro.myplace4parts.com',
  generateV3TokenUrl:
    'https://ms.myplace4parts.com/userinfo/1.0/generateV3Token',
  getSalesPadsUrl: 'https://intranet.myplace4parts.com/salespad/1.0/acctOrgs',
  createSalesRepUserInfoUrl:
    'https://ms.myplace4parts.com/userinfo/1.0/createSalesRepUserInfoCognito',
  unAuthorisedCredentialsUrl:
    'https://allianceconnect.com/o/aapa_jax_rest_services/organization/createUser',
  termsAndConditionsUrl:
    'https://ms.myplace4parts.com/userinfo/1.0/accept-eula',
}

const environmentQA: Environment = {
  formSubmitUrl: 'qa.shop.myplace4parts.com/UserLogin/autologin',
  communities:
    'https://qa.allianceconnect.com/o/aapa_jax_rest_services/cognito/user-communities',
  token: '',
  userName: '',
  userMailId: '',
  subDomain: '',
  forgotPassword:
    'https://qa.allianceconnect.com/o/aapa_jax_rest_services/cognito/send-forgotpassword-otp',
  resetPassword:
    'https://qa.allianceconnect.com/o/aapa_jax_rest_services/cognito/validateotp-changepassword',
  eula_restPassword:
    'https://qa.allianceconnect.com/o/aapa_jax_rest_services/organization/user/reset-password',
  v3Url: 'qa.pro.myplace4parts.com',
  generateV3TokenUrl:
    'https://qa.ms.myplace4parts.com/userinfo/1.0/generateV3Token',
  getSalesPadsUrl:
    'https://qa.intranet.myplace4parts.com/salespad/1.0/acctOrgs',
  createSalesRepUserInfoUrl:
    'https://qa.ms.myplace4parts.com/userinfo/1.0/createSalesRepUserInfoCognito',
  unAuthorisedCredentialsUrl:
    'https://qa.allianceconnect.com/o/aapa_jax_rest_services/organization/createUser',
  termsAndConditionsUrl:
    'https://qa.ms.myplace4parts.com/userinfo/1.0/accept-eula',
}

const environmentStaging: Environment = {
  formSubmitUrl: 'staging.shop.myplace4parts.com/UserLogin/autologin',
  communities:
    'https://staging.allianceconnect.com/o/aapa_jax_rest_services/cognito/user-communities',
  token: '',
  userName: '',
  userMailId: '',
  subDomain: '',
  forgotPassword:
    'https://staging.allianceconnect.com/o/aapa_jax_rest_services/cognito/send-forgotpassword-otp',
  resetPassword:
    'https://staging.allianceconnect.com/o/aapa_jax_rest_services/cognito/validateotp-changepassword',
  eula_restPassword:
    'https://staging.allianceconnect.com/o/aapa_jax_rest_services/organization/user/reset-password',
  v3Url: 'staging.pro.myplace4parts.com',
  generateV3TokenUrl:
    'https://staging.ms.myplace4parts.com/userinfo/1.0/generateV3Token',
  getSalesPadsUrl:
    'https://staging.intranet.myplace4parts.com/salespad/1.0/acctOrgs',
  createSalesRepUserInfoUrl:
    'https://staging.ms.myplace4parts.com/userinfo/1.0/createSalesRepUserInfoCognito',
  unAuthorisedCredentialsUrl:
    'https://staging.allianceconnect.com/o/aapa_jax_rest_services/organization/createUser',
  termsAndConditionsUrl:
    ' https://staging.ms.myplace4parts.com/userinfo/1.0/accept-eula',
}

export const getEnvironment = (): Environment => {
  if (import.meta.env.MODE === 'qa' || import.meta.env.MODE === 'qa-dr') {
    return environmentQA
  }

  if (
    import.meta.env.MODE === 'prod' ||
    import.meta.env.MODE === 'prod-dr'
  ) {
    return environmentProduction
  }

  return environmentStaging
}
