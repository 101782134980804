export interface Environment {
  formSubmitUrl: string
  communities: string
  token: string
  userName: string
  userMailId: string
  subDomain: string
  forgotPassword: string
  resetPassword: string
  eula_restPassword: string
  v3Url: string
  generateV3TokenUrl: string
  getSalesPadsUrl: string
  createSalesRepUserInfoUrl: string
  unAuthorisedCredentialsUrl: string
  termsAndConditionsUrl: string
}

export interface AwsConfig {
  identityPoolId: string
  userPoolId: string
  userPoolClientId: string
  aws_appsync_graphqlEndpoint: string
  aws_appsync_region: 'us-east-1' | 'us-west-2'
  aws_appsync_authenticationType: string
}

export interface ILoginInfo {
  userName: string
  password: string
}

export interface ICommunity {
  orgName?: string | undefined
  logo: string
  community?: string
  friendlyURL: string
  status: string
}

export enum mapEntries {
  ScreenNameDoesNotExist = 'Screen name does not exist',
  ScreenNameAlreadyExists = 'Screen name already exists',
  InvalidCredentials = "Invalid credentials. Please utilize the 'Forgot Password' Link to reset your password or reach out to your administrator for assistance with updating your password.",
  InvalidPasswordFormat = 'Invalid password format',
  PleaseEnterPassword = 'Please enter password.',
  IncorrectScreenName = 'Incorrect screen name.',
  InvalidPhoneNumberFormat = 'Invalid phone number format',
  InvalidPhoneNumberFormatDetails = 'Invalid phone number format. Please use a phone number format of +12345678900',
}

export enum refreshTokenKeys {
  deviceGroupKey = 'deviceGroupKey',
  LastAuthUser = 'LastAuthUser',
  deviceKey = 'deviceKey',
  randomPasswordKey = 'randomPasswordKey',
  clockDrift = 'clockDrift',
  accessToken = 'accessToken',
  idToken = 'idToken',
  refreshToken = 'refreshToken',
}

export interface ICreateSalesPadRepResponse {
  message: string
  statusCode: number
  key: string
}

export interface refreshTokenState {
  userId: string | null
  orgId: string | null
  userName: string
  deviceGroupKey: string | null
  LastAuthUser: string | null
  deviceKey: string | null
  randomPasswordKey: string | null
  clockDrift: string | null
  accessToken: string | null
  idToken: string | null
  refreshToken: string | null
}

export interface IOrganization {
  orgName: string
  logo: string
  friendlyURL: string
  status: string
}

export interface ISalesPadResponse {
  Data?: ISalesPadAssociatedOrg[]
  status?: string
}

export interface IUserAttributes {
  orgsInfo: Array<IOrganization | ICommunity>
  ErrorAccess: boolean
  noAccess: boolean
  spaceAccess: boolean
  myplaceAccess: boolean
  bothAccess: boolean
  displayUserName: string
  formUrl: string
  shopFormsubmitUrl: string
  CognitoIdentityServiceProvider: string
  userView: boolean
  user: string
  salesPadUserAssociatedOrgs?: ISalesPadResponse
  errorMessage: string
  signedIn: boolean
  greeting: string
  userName: string
  userId: string
  postPayload: string
  promoStatus: boolean
  myplaceInfo: Array<ICommunity>
  spaceInfo: Array<ICommunity>
  screenName: string
  language: string
  V3Access: { acessType: string; v3Logo: string }
  displaySalespadOrgs: boolean
  email: string
  redirectUrl: string | undefined
  orgsData: Array<IOrganization>
}

interface Access {
  accessType: string
  v3logo: string
}

interface Organization {
  orgName: string
  logo: string
  friendlyURL: string
  status: string
}

export interface IUser {
  eual_accepted: boolean
  access: Access
  password_reset: boolean
  isAssignedToSpace: boolean
  isAssignedToMyPlace: boolean
  screenName: string
  orgs: Organization[]
  communities: Array<ICommunity>
}

export interface ISalesPadAssociatedOrg {
  childOrgId: number
  childOrgName: string
  id: number
  isCSC: boolean
  status: number
}

export interface IGetCommunitiesApiResponse {
  data: Array<IUser> // Define the type of the data if it's more specific
  message: string
  status: number
  errorDetails: string
  redirectUrl: string
}

export interface UserAttributesResp {
  data: {
    get: {
      result: string
    }
  }
}

export interface UserAttributes extends Record<string, string | undefined> {
  language: string
  defaultSort: string
  defaultFilters: string
  display_partsTypeAll: string
  display_filtering: string
  display_userFiltering: string
  display_priceFld: string
  findit_partsProfessionalN: string
  phoneNumber: string
  findit_orderIfNotAvail: string
  EULA_accepted: string
  epeFeatures_buyersGuide: string
  epeFeatures_defaultState: string
  epeFeatures_labor: string
  epeFeatures_plateToVin: string
  orgName: string
  userId: string
  mclOrgId: string
  orgId: string
  wdorgId: string
  searchPreferences: string
  searchPreferencesVersion: string
  searchPreferences_defaultSort: string
  dds: string
  cart_locPartMsg?: string
  cart_noteMaxSize: string
  cart_noteRequired: string
  cart_poMaxSize: string
  cart_pnMaxSize: string
  cart_poRequired: string
  cart_multiCart: string
  partDomain: string
  display_perCarQtyOne: string
  screenName: string
  courrierAcctId: string
  treePath: string
  shipmentStatusTrackerEnabled: string
  cart_nonPrimaryLocWarningEnabled: string
  cart_shipToOnCart: string
}

export enum UserAttributeKey {
  dds = 'dds',
  display_partsTypeAll = 'display_partsTypeAll',
  defaultFilters = 'defaultFilters',
  defaultSort = 'defaultSort',
  displaySortOrder = 'display_sortOrder',
  epeFeatures_buyersGuide = 'epeFeatures_buyersGuide',
  epeFeatures_defaultState = 'epeFeatures_defaultState',
  displayLabor = 'epeFeatures_labor',
  displayPlateSearch = 'epeFeatures_plateToVin',
  findit_partsProfessionalN = 'findit_partsProfessionalN',
  findit_orderIfNotAvail = 'findit_orderIfNotAvail',
  GFX_Enabled = 'epeFeatures_gfx',
  language = 'language',
  orgName = 'orgName',
  phoneNumber = 'phoneNumber',
  searchPreferences = 'searchPreferences',
  searchPreferencesVersion = 'searchPreferencesVersion',
  searchPreferencesDefaultSort = 'searchPreferences_defaultSort',
  userId = 'userId',
  partDomain = 'partDomain',
  cartNoteHasVehicle = 'cart_noteHasVehicle',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  epeFeatures_labor = 'epeFeatures_labor',
  courrierAcctId = 'courrierAcctId',
  treePath = 'treePath',
  shipmentStatusTrackerEnabled = 'shipmentStatusTrackerEnabled',
  cart_nonPrimaryLocWarningEnabled = 'cart_nonPrimaryLocWarningEnabled',
  displayShipToAddressInCartPage = 'cart_shipToOnCart',
  display_priceFld = 'display_priceFld',
}

export enum LanguageKey {
  ENGLISH = 'en_US',
  SPANISH = 'es_MX',
  FRENCH = 'fr_CA',
}

export interface Language {
  id: number
  name: string
  key: LanguageKey
  image: string
  url: string
}

export interface ISalesPadAssociatedOrg {
  childOrgId: number
  childOrgName: string
  id: number
  isCSC: boolean
  status: number
}

export interface ICreateSalesPadRepResponse {
  key: string
  message: string
  statusCode: number
}
