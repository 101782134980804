import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from '@tanstack/react-location'
import { LanguageKey } from '../interfaces'
import { getEulaContent, acceptEula } from '../Services/WidgetService'
import { usePostPayload } from '../utils/PostPayloadContext'
import './TermsAndConditions.scss'
import { Sanitized } from './Sanitized'
import CA from '../assets/CA.png'
import MX from '../assets/MX.png'
import US from '../assets/US.png'
import expand from '../assets/expand.png'
import { getEnvironment } from '../Environment'
import logo from '../assets/mpfp-logo.png'

const environment = getEnvironment()

const TermsAndConditions = (): ReactElement => {
  const navigate = useNavigate()
  const [conditions, setConditions] = useState<string>('')

  const { postPayload, setLoader } = usePostPayload()

  const languages = useMemo(
    () => [
      {
        id: 1,
        name: 'ENGLISH',
        key: LanguageKey.ENGLISH,
        image: US,
        url: 'https://s3.amazonaws.com/myplace-eula-dxp/2019010806+MyPlace+Terms+of+Use+EULA--English.htm',
      },
      {
        id: 2,
        name: 'SPANISH',
        key: LanguageKey.SPANISH,
        image: MX,
        url: 'https://s3.amazonaws.com/myplace-eula-dxp/2019010806+MyPlace+Terms+of+Use+EULA--Spanish.htm',
      },
      {
        id: 3,
        name: 'FRENCH',
        key: LanguageKey.FRENCH,
        image: CA,
        url: 'https://s3.amazonaws.com/myplace-eula-dxp/2019010806+MyPlace+Terms+of+Use+EULA--English.htm',
      },
    ],
    []
  )
  const [selectedLang, setSelectedLang] = useState(languages[0])

  const languageItem = useMemo(() => {
    return (
      languages.find((item) => item.name === selectedLang.name)?.url ||
      languages[0].url
    )
  }, [languages, selectedLang.name])

  const [enableAgreeBtn, setEnableAgreeBtn] = useState(true)
  const [cssDisplay, setCssDisplay] = useState('none')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScroll = (e: any) => {
    const bottom =
      Math.floor(e.target.scrollHeight - e.target.scrollTop - 50) <
      e.target.clientHeight
    setEnableAgreeBtn(!bottom)
  }

  const fetchEulaContent = useCallback(async () => {
    setLoader(true)
    try {
      const eulaContent = await getEulaContent(languageItem)
      setConditions(eulaContent.replace(/�/g, ''))
    } catch (error) {
      console.error('Failed to fetch EULA content:', error)
    } finally {
      setLoader(false)
    }
  }, [languageItem, setLoader])

  useEffect(() => {
    fetchEulaContent()
  }, [fetchEulaContent])

  const handleEulaAcceptance = async () => {
    const queryParams = { EULA_accepted: true }
    const response = await acceptEula({
      url: environment.termsAndConditionsUrl,
      queryParams,
      idToken: postPayload?.idToken ?? '',
    })
    if (response?.status === 200) {
      navigate({ to: '/' })
    }
  }

  const showDropdown = () => {
    if (cssDisplay === 'none') {
      setCssDisplay('block')
    } else {
      setCssDisplay('none')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectListItem = (sOption: any) => {
    setCssDisplay('none')
    setSelectedLang(sOption)
  }

  return (
    <>
      <div className="LogoContainer">
        <div className="langContainer">
          <img className="tcLogo" src={logo} alt="Logo" />
          <div className="DropDownContainer">
            <button onClick={showDropdown}>
              <img className="selected-image-option" src={selectedLang.image} />{' '}
              {selectedLang.name} <img className="expand-img" src={expand} />
            </button>
            <div className="lang-container">
              <ul
                className="lang-option-container"
                style={{ display: cssDisplay }}
              >
                {languages.map((lang) => (
                  <li
                    className="lang-li-options"
                    onClick={() => {
                      selectListItem(lang)
                    }}
                    key={lang.id}
                  >
                    <img
                      className="lang-image-option"
                      src={lang.image}
                      height="30"
                      width="30"
                      alt="flagpic"
                    />
                    {lang.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="TermsContainer" onScroll={handleScroll}>
        <Sanitized html={conditions}></Sanitized>
      </div>
      <div className="agreeContainer">
        <span>Please scroll to bottom</span>
        <button
          type="button"
          onClick={handleEulaAcceptance}
          className="AgreeButton"
          disabled={enableAgreeBtn}
        >
          I Agree
        </button>
      </div>
    </>
  )
}

export default TermsAndConditions
