import { useEffect, useState } from 'react'
import mainLogo from '../assets/mpfp-logo.png'
import allainceConnectLogo from '../assets/allience-connect.png'
import './Login.scss'
import { LoginService, getCurrentUserDetails } from '../Services/LoginService'
import { useNavigate } from '@tanstack/react-location'
import { useAuth } from '../utils/AuthContext'
import { usePostPayload } from '../utils/PostPayloadContext'

const LoginComponent = () => {
  const { setIsAuthenticated } = useAuth()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [username, setUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const navigate = useNavigate()
  const { setLoader } = usePostPayload()

  useEffect(() => {
    setLoader(true)
    getCurrentUserDetails()
      .then((user) => {
        if (user && user.username) {
          setIsAuthenticated(true)
          navigate({ to: '/' })
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoader(false)
      })
  }, [navigate, setIsAuthenticated, setLoader])

  const handleLogin = () => {
    if (!username.length || username === '') {
      setErrorMessage('Please Enter a ScreenName')
    } else if (!password.length || password === '') {
      setErrorMessage('Password is required for Login')
    } else {
      setLoader(true)
      LoginService({
        userName: username,
        password,
      })
        .then(() => {
          setIsAuthenticated(true)
          navigate({ to: '/', replace: true })
        })
        .catch((error) => {
          setErrorMessage(error.message)
          setLoader(false)
        })
    }
  }

  return (
    <div>
      <div className="loginFormParent">
        <p className="welcome-text">Welcome to MyPlace4Parts</p>
      </div>
      <div className="loginFormParent">
        <div className="loginContainer">
          <div className="loginForm">
            <img className="main-logo" src={mainLogo} />
            <form className="formClass">
              <input
                className="input_username"
                type="text"
                id="username"
                name="name"
                value={username}
                placeholder="Screen name"
                onChange={(e) => setUserName(e?.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin()
                  }
                }}
              />
              <input
                className="input_password"
                type="password"
                id="password"
                name="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e?.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin()
                  }
                }}
              />
              <input
                className="log_btn_signIn"
                type="button"
                value="SIGN IN"
                id="submit-button"
                onClick={handleLogin}
              />
              <p className="errormessages_cls">{errorMessage}</p>
            </form>
          </div>
        </div>
      </div>
      <div className="footer-block">
        <p className="signin-txt">Please sign in to access MyPlace4Parts.</p>
        <p
          className="forgot-txt"
          onClick={() =>
            navigate({
              to: '/forgot-password',
            })
          }
        >
          Forgot Password
        </p>
        <p className="assistance-txt">
          If you did not receive an email containing login information, contact
          your Parts Supplier.
        </p>
        <a className="transition-txt">
          <img className="sub-logo" src={allainceConnectLogo} />
        </a>
      </div>
    </div>
  )
}

export default LoginComponent
