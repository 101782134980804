import {
  ReactNode,
  createContext,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
} from 'react'
import { ISalesPadAssociatedOrg, refreshTokenState } from '../interfaces'
import { UserAttributes } from '../interfaces'

import './PayloadContext.scss'
interface IPostPayloadContext {
  loader: boolean
  postPayload: refreshTokenState | null
  openSalesPadModal: boolean
  salesPadData: ISalesPadAssociatedOrg[]
  setPostPayload: Dispatch<SetStateAction<refreshTokenState | null>>
  setLoader: Dispatch<SetStateAction<boolean>>
  setOpenSalesPadModal: Dispatch<SetStateAction<boolean>>
  setISalesPadData: Dispatch<SetStateAction<ISalesPadAssociatedOrg[]>>
  graphQlUserAttributes: object | null
  setGraphQlUserAttributes: Dispatch<SetStateAction<UserAttributes | null>>
}

interface AuthProviderProps {
  children: ReactNode
}

const PostPayloadContext = createContext<IPostPayloadContext | undefined>(
  undefined
)

export const PostPayloadProvider: React.FC<AuthProviderProps> = ({
  children,
}) => {
  const [postPayload, setPostPayload] = useState<refreshTokenState | null>(null)
  const [loader, setLoader] = useState<boolean>(false)
  const [openSalesPadModal, setOpenSalesPadModal] = useState<boolean>(false)
  const [salesPadData, setISalesPadData] = useState<ISalesPadAssociatedOrg[]>(
    []
  )
  const [graphQlUserAttributes, setGraphQlUserAttributes] =
    useState<UserAttributes | null>(null)

  return (
    <PostPayloadContext.Provider
      value={{
        loader,
        setLoader,
        postPayload,
        salesPadData,
        setPostPayload,
        openSalesPadModal,
        setOpenSalesPadModal,
        graphQlUserAttributes,
        setGraphQlUserAttributes,
        setISalesPadData,
      }}
    >
      <div className="LoaderParent">
        {loader && (
          <div className="LoaderClass">
            <div className="LoaderClassChild">
              <div className="page-loader"></div>
            </div>
          </div>
        )}
        <div className="childrenClass">{children}</div>
      </div>
    </PostPayloadContext.Provider>
  )
}

// Custom hook for using the context
// eslint-disable-next-line react-refresh/only-export-components
export const usePostPayload = () => {
  const context = useContext(PostPayloadContext)
  if (!context) {
    throw new Error('usePostPayload must be used within a PostPayloadProvider')
  }
  return context
}

export default PostPayloadProvider
