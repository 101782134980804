import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Amplify } from 'aws-amplify'
import { getAwsConfig } from './aws-exports.ts'

Amplify.configure({
  Auth: {
    Cognito: getAwsConfig(),
  },
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
