import { ReactElement, useEffect, useState } from 'react'
import LoginComponentUserAttributes from '../utils/UserAttributes'
import './LandingPage.scss'
import { usePostPayload } from '../utils/PostPayloadContext'
import BothAccess from './BothAccess'
import SpaceAcess from './SpaceAccess'
import ErrorAccess from './ErrorAccess'
import NoAccess from './NoAccess'
import MyPlaceAcess from './MyPlaceAccess'
import { useSearch } from '@tanstack/react-location'
import { getCurrentUserDetails } from '../Services/LoginService'

const HomePage = (): ReactElement => {
  const { setLoader, setOpenSalesPadModal, salesPadData } = usePostPayload()

  const [, setUserName] = useState<string>('')
  const { userAttributes, fetchUserDetails, setUserAttributes } =
    LoginComponentUserAttributes()

  useEffect(() => {
    getCurrentUserDetails().then((user) => {
      setUserName(user.signInDetails?.loginId || '')
    })
  }, [])

  useEffect(() => {
    setLoader(true)
    fetchUserDetails().finally(() => {
      setLoader(false)
    })
  }, [fetchUserDetails, setLoader])

  const search = useSearch()
  useEffect(() => {
    if (search?.tokenData) {
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        userView: false,
        // SetTokens()
      }))
    }
    if (search.salespad) {
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        displaySalespadOrgs: true,
      }))
      if (salesPadData && salesPadData.length > 0) {
        setOpenSalesPadModal(true)
      }
    }
  }, [salesPadData, search, setOpenSalesPadModal, setUserAttributes])

  return (
    <div>
      {userAttributes.myplaceAccess && (
        <MyPlaceAcess userAttributes={userAttributes} />
      )}
      {userAttributes.spaceAccess && (
        <SpaceAcess userAttributes={userAttributes} />
      )}
      {userAttributes.bothAccess && (
        <BothAccess userAttributes={userAttributes} />
      )}
      {userAttributes.noAccess && <NoAccess />}
      {userAttributes.ErrorAccess && (
        <ErrorAccess userAttributes={userAttributes} />
      )}
    </div>
  )
}

export default HomePage
