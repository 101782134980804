import { ReactElement, useState } from 'react'
import './ResetPassword.scss'
import { ResetPasswordPayLoad } from '../Services/WidgetService'
import { resetPassword } from '../Services/WidgetService'
import { usePostPayload } from '../utils/PostPayloadContext'
import { getEnvironment } from '../Environment'
import logo from '../assets/mpfp-logo.png'

const environment = getEnvironment()

const ResetPassword = (): ReactElement => {
  const [resetStatus, setResetStatus] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfrimPassword] = useState<string>('')

  const responseCodes = ['1002', '1003', '1004', '1005', '1006']

  const { postPayload } = usePostPayload()

  const clearFields = () => {
    setConfrimPassword('')
    setCode('')
    setNewPassword('')
  }
  const restPassword = () => {
    if (!code || code.length === 0) {
      setErrorMessage('Please enter code')
    } else if (newPassword.length === 0 || newPassword === '') {
      setErrorMessage('Please Enter a new password')
    } else if (confirmPassword.length === 0 || confirmPassword === '') {
      setErrorMessage('Please enter confirm password')
    } else if (newPassword !== confirmPassword) {
      setErrorMessage(
        'The new password and confirmation password do not match.'
      )
    } else {
      const userName = window.localStorage.getItem('userName')
      const payLoad: ResetPasswordPayLoad = {
        userName,
        password: confirmPassword,
        otp: code,
      }
      resetPassword(payLoad, postPayload?.idToken)
        .then((response) => {
          const checkTheStatusCode = responseCodes.find(
            (item) => item === String(response?.data?.statusCode)
          )
          if (response?.data?.statusCode === 400) {
            setErrorMessage(response.data['message'])
          } else if (response?.data?.statusCode === 1001) {
            setErrorMessage(response.data['message'])
          } else if (checkTheStatusCode) {
            setErrorMessage(response?.data['message'])
          }
          if (response?.data?.statusCode === 200) {
            clearFields()
            setResetStatus(false)
          }
        })
        .catch((error) => {
          console.log('error in reset password', error)
        })
    }
  }

  return (
    <div className="resetContainer">
      <div className="loginParentContaniner">
        <div className="loginContainer">
          <div className="loginForm">
            <img className="main-logo" src={logo} alt="Logo" />
            {resetStatus ? (
              <div className="resetPasswordContainer">
                <h6 className="reset-title">RESET YOUR PASSWORD</h6>
                <p className="reset-caption">
                  Enter the code which is sent to {environment.userMailId}
                </p>

                <input
                  className="input_username"
                  type="text"
                  id="code"
                  value={code}
                  name="code"
                  placeholder="Enter Code"
                  onChange={(e) => setCode(e.target.value)}
                  onKeyUp={(e) => e.key === 'Enter' && restPassword()}
                />
                <input
                  className="input_password"
                  type="password"
                  id="NewPassword"
                  name="NewPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter New Password"
                  onKeyUp={(e) => e.key === 'Enter' && restPassword()}
                />
                <input
                  className="input_password"
                  type="password"
                  id="confirmNewPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfrimPassword(e.target.value)}
                  name="confirmNewPassword"
                  placeholder="Confirm New Password"
                  onKeyUp={(e) => e.key === 'Enter' && restPassword()}
                />
                <br />
                <p className="errormessages_cls">{errorMessage}</p>
                <input
                  className="btn_signIn"
                  type="button"
                  value="RESET"
                  id="submit-button"
                  onClick={restPassword}
                />
                <p className="redirect-click">
                  Click here to{' '}
                  <a className="redirect-login" href="/login">
                    <b>LOGIN</b>
                  </a>
                </p>
              </div>
            ) : (
              <div>
                <p className="reset-success">
                  <b>YOUR PASSWORD HAS BEEN RESET SUCCESSFULLY</b>
                </p>
                <p className="redirect-click">
                  Click here to{' '}
                  <a className="redirect-login" href="/login">
                    <b>LOGIN</b>
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ResetPassword
