import axios from 'axios'

// Create an instance of axios
const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

const setAuthHeader = (token: string | null | undefined) => {
  if (token) {
    axiosInstance.defaults.headers['Authorization'] = token
  } else {
    delete axiosInstance.defaults.headers['Authorization']
  }
}

const httpService = {
  get: (url: string, params = {}, token: string | null | undefined) => {
    setAuthHeader(token)
    return axiosInstance.get(url, { params })
  },
  post: (url: string, params = {}, token: string) => {
    setAuthHeader(token)
    return axiosInstance.post(url, params)
  },
  postWithoutToken: (url: string, params = {}) => {
    return axiosInstance.post(url, params)
  },
  patch: (url: string, params = {}, headers = {}) => {
    return axiosInstance.patch(url, params, { headers })
  },
  put: (url: string, params = {}, token: string) => {
    setAuthHeader(token)
    return axiosInstance.put(url, params)
  },
  delete: (url: string, params = {}) => {
    return axiosInstance.delete(url, { params })
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getTermsAndConditions: (url: string, _params = {}) => {
    return axiosInstance.get(url, { responseType: 'text' })
  },
}

export default httpService
