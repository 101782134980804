import { ReactElement, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import '../LoginPage/Login.scss'
import Col from 'react-bootstrap/esm/Col'
import { usePostPayload } from '../utils/PostPayloadContext'
import Card from 'react-bootstrap/Card'
import {
  CreateSalesRepUserInfo,
  getRedirectiontoken,
} from '../Services/WidgetService'
import { getEnvironment } from '../Environment'

const environment = getEnvironment()

const SalesPadUserModal = (): ReactElement => {
  const [show] = useState(true)
  const { salesPadData, postPayload, setOpenSalesPadModal } = usePostPayload()
  const [, setRedirectingToV3] = useState(false)
  const [selectedOrgId, setSelectedOrgId] = useState<string>('')
  const [salesPadLoader, setSalesPadLoader] = useState(false)
  const [filterSalesPadData, setFilterSalesPadData] = useState(salesPadData)
  const [checkbox, setCheckbox] = useState(true)

  const handleLogin = (orgId: string) => {
    setRedirectingToV3(true)
    setSelectedOrgId(orgId)
    setSalesPadLoader(true)
    try {
      CreateSalesRepUserInfo(
        orgId,
        postPayload?.userId ? postPayload.userName : '',
        postPayload?.idToken ? postPayload.idToken : ' '
      ).then((resp) => {
        const { data } = resp
        try {
          getRedirectiontoken(
            data.key,
            window.location.origin,
            postPayload?.idToken ? postPayload?.idToken : ''
          ).then((response) => {
            setSalesPadLoader(false)
            if (environment.subDomain === '') {
              window.location.href = `https://${environment.v3Url}/auto-login?token=${response.data.token}&impersonate=true&salespad=true`
            } else {
              window.location.href = `https://${environment.subDomain}.${environment.v3Url}/auto-login?token=${response.data.token}&impersonate=true&salespad=true`
            }
            setRedirectingToV3(false)
          })
        } catch (error) {
          throw new Error()
        }
      })
    } catch (error) {
      if (error) throw new Error()
    }
  }

  return (
    <Modal show={show} onHide={() => setOpenSalesPadModal(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Associated organizations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="search-box">
          <Col sm="10" md="6">
            <Form.Control
              type="text"
              placeholder="Search users(Account name,Organization name)"
              onChange={(e) => {
                const searchTerm = e.target.value.toLowerCase().trim()
                if (searchTerm) {
                  const filteredData = salesPadData.filter((data) =>
                    data.childOrgName.toLowerCase().includes(searchTerm)
                  )
                  setFilterSalesPadData(filteredData)
                } else {
                  setFilterSalesPadData(salesPadData.slice())
                }
              }}
            ></Form.Control>
          </Col>
          <Col md="3">
            <Form.Check
              type="checkbox"
              id="default-radio"
              label="CSC only"
              onChange={() => setCheckbox(!checkbox)}
            />
          </Col>
        </Form>
        <div className="cardParentClass">
          {checkbox &&
            filterSalesPadData.map((item) => {
              return (
                <Card
                  onClick={() => handleLogin(String(item.childOrgId))}
                  style={{ width: '149px', margin: '6px' }}
                >
                  <Card.Body>
                    {salesPadLoader &&
                    selectedOrgId === String(item.childOrgId) ? (
                      <span className="salespad-loader"></span>
                    ) : (
                      item.childOrgName
                    )}
                  </Card.Body>
                </Card>
              )
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => setOpenSalesPadModal(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SalesPadUserModal
