import { useNavigate } from '@tanstack/react-location'
import { ICommunity, IUserAttributes } from '../interfaces'
import LoginComponentUserAttributes from '../utils/UserAttributes'
import { signOut } from '@aws-amplify/auth'

interface IAcessProps {
  userAttributes: IUserAttributes
}

const SpaceAcess = (props: IAcessProps) => {
  const { userAttributes } = props
  const { shouldDisplayV3Logo, handleSignIn, handleV3SignIn } =
    LoginComponentUserAttributes()
  const handleV3SignInUser = () => {
    handleV3SignIn(userAttributes)
  }
  const navigate = useNavigate()
  const handleSignInUser = (spaceInfo: ICommunity[]) => {
    handleSignIn(spaceInfo, userAttributes)
  }

  const handleSignout = () => {
    signOut()
    localStorage.clear()
    navigate({ to: '/login', replace: true })
  }
  return (
    <div>
      <div className="welcome-block">
        <p className="welcome-txt">Welcome to MyPlace4Parts.</p>
      </div>
      <div>
        <div className="userContainer">
          <div className="user-block">
            <p className="userName">
              Welcome <b>{userAttributes.displayUserName}</b>
            </p>
            {shouldDisplayV3Logo(userAttributes) && (
              <img
                className="userLogo"
                src={userAttributes.V3Access.v3Logo}
                onClick={handleV3SignInUser}
              />
            )}
            {userAttributes.V3Access?.acessType == '0' && (
              <img
                className="userLogo"
                src="spaceInfo.logo"
                onClick={() => handleSignInUser(userAttributes.spaceInfo)}
              />
            )}

            <input
              className="btn-signout"
              type="button"
              value="SIGN OUT"
              id="submit-button"
              onClick={handleSignout}
            />
          </div>
        </div>
      </div>
      <div className="thanks-block">
        <p className="thanks-txt">
          Thank you for logging in! Click the logo to access MyPlace4Parts.
        </p>
        <p className="thanks-txt2">
          You also have access to your supplier MyPlace Dashboard and Alliance
          Connect.
        </p>
      </div>
      <div className="bottom-logos-block">
        {userAttributes.orgsInfo.map((org) => {
          return (
            <img
              src={org.logo}
              className="orgLogo"
              onClick={() => handleSignInUser(userAttributes.orgsInfo)}
            />
          )
        })}
        {userAttributes.V3Access?.acessType == '2' && (
          <p className="thanks-txt2">
            The legacy version of MyPlace will remain available for a limited
            period of time.
          </p>
        )}
        {userAttributes.V3Access?.acessType == '2' && (
          <p
            className="thanks-txt2 shop-navigation"
            onClick={() => handleSignInUser(userAttributes.myplaceInfo)}
          >
            Click here to access it
          </p>
        )}
      </div>
    </div>
  )
}

export default SpaceAcess
