import { useEffect } from 'react'
import './SignoutPage.scss'
import { signOut } from '@aws-amplify/auth'
import { useNavigate } from '@tanstack/react-location'

const SignoutPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    signOut()
    localStorage.clear()
    navigate({ to: '/login' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <></>
}

export default SignoutPage
