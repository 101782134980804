import {
  ReactLocation,
  Router,
  Outlet,
  Navigate,
} from '@tanstack/react-location'
import LoginComponent from './LoginPage/Login'
import HomePage from './LadingPage/HomePage'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import { AuthProvider, useAuth } from './utils/AuthContext'
import './App.css'
import { PostPayloadProvider } from './utils/PostPayloadContext'
import TermsAndConditions from './EULA/TermsAndConditions'
import ResetPassword from './ResetPassword/ResetPassword'
import NoAccess from './LadingPage/NoAccess'
import SignoutPage from './SignoutPage/SignoutPage'
import { ReactElement } from 'react'

const location = new ReactLocation()

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? children : <Navigate to="/login" replace />
}

const routes = () => {
  return [
    {
      path: '/login',
      element: <LoginComponent />,
    },
    {
      path: '/reset-password',
      element: <ResetPassword />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />,
    },
    {
      path: '/no-access',
      element: <NoAccess />,
    },
    {
      path: '/signout',
      element: <SignoutPage />,
    },
    {
      path: '/',
      element: (
        <RequireAuth>
          <HomePage />
        </RequireAuth>
      ),
    },
    {
      path: '*',
      element: <LoginComponent />,
    },
  ]
}

function App(): ReactElement {
  return (
    <PostPayloadProvider>
      <AuthProvider>
        <Router location={location} routes={routes()}>
          <Outlet />
        </Router>
      </AuthProvider>
    </PostPayloadProvider>
  )
}

export default App
