import { ILoginInfo } from '../interfaces'
import { signIn } from 'aws-amplify/auth'
import { getCurrentUser } from 'aws-amplify/auth'

export const LoginService = async (userInfo: ILoginInfo) => {
  try {
    const user = await signIn({
      username: userInfo.userName.trim().toLocaleLowerCase(),
      password: userInfo.password.trim(),
    })
    return user
  } catch (error) {
    console.log('Error signing in:', error)
    throw error
  }
}

export const getCurrentUserDetails = async () => {
  try {
    const currentUser = await getCurrentUser()
    return currentUser
  } catch (error) {
    console.log('Error getting current user:', error)
    throw error
  }
}
